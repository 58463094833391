<template>
    <b-card title="Vloge">
        <Table class="mt-2" :columns="columns" :data="items" v-slot="props">
            <span v-if="props.column.display_type === 1">
                <router-link :to="{name: 'submission', params: {id: props.row.id}}">{{ props.formattedRow[props.column.field] }}</router-link>
            </span>
            <span v-else-if="props.column.display_type === 2">
                <b-badge v-if="props.row.status === 1" variant="success">Privzeto</b-badge>
                <b-badge v-else variant="danger">Zavrnjeno</b-badge>
            </span>
            <span v-else-if="props.column.display_type === 3" class="d-flex justify-content-center">
                <b-button v-if="props.row.status === 1" variant="danger" @click="submissionDeny(props.row)"><fa icon="trash"/></b-button>
            </span>
            <span v-else-if="props.column.display_type === 4" class="d-flex flex-column justify-content-center">

                <div>
                    <span>Odločba o otroškem dodatku:</span>
                    <template v-if="props.row.child_allowance_document_name">
                        <a class="text-primary" @click="downloadPdf(props.row.child_allowance_document_name)"> Prenesi</a>
                    </template>
                    <span v-else> /</span>
                </div>

                <div>
                    <span>Odločba o prepovedi stikov:</span>
                    <template v-if="props.row.restraining_order_attachment_name">
                        <a class="text-primary" @click="downloadPdf(props.row.restraining_order_attachment_name)"> Prenesi</a>
                    </template>
                    <span v-else> /</span>
                </div>
                <div>
                    <span>Prijavnica:</span>
                    <template v-if="props.row.submission_pdf_name">
                        <a class="text-primary" @click="downloadPdf(props.row.submission_pdf_name)"> Prenesi</a>
                    </template>
                    <span v-else> /</span>
                </div>

            </span>
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
        </Table>
    </b-card>
</template>

<script>
    import {BCard, BButton, BBadge} from 'bootstrap-vue'
    import Table from '@/views/components/Table.vue'

    export default {
        components: {
            BBadge,
            Table,
            BCard,
            BButton
        },
        data() {
            return {
                columns: [
                    {
                        field: (value) => { return [value.first_name, value.last_name, value.gender, value.id] },
                        label: 'Ime in priimek (spol)',
                        tdClass: 'align-middle font-weight-bold',
                        formatFn: (values) => {
                            return `${values[0]} ${values[1]} (${this.gender(values[2])})`
                        },
                        filterOptions: {
                            enabled: true,
                            filterFn: (data, search) => {
                                if ((`${data[0]} ${data[1]}`).toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) return true
                                else if (this.gender(data[2]) === 'M' && ('M').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) return true
                                else if (this.gender(data[2]) === 'Ž' && ('Ž').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) return true
                                return false
                            }
                        },
                        display_type: 1
                    },
                    {
                        field: 'emso',
                        label: 'Emšo',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'municipality.name',
                        label: 'Občina',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'term.name',
                        label: 'Termin',
                        tdClass: 'align-middle',
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: []
                        }
                    },
                    {
                        field: 'submission_type',
                        label: 'Tip letovanja',
                        tdClass: 'align-middle',
                        formatFn: (type) => {
                            if (type === 1) return 'Samoplačniško'
                            else if (type === 2) return 'Zdravstveno'
                            else if (type === 3) return 'Subvencionirano s strani občine'
                            else if (type === 4) return 'Subvencionirano s strani občine in zdravstva'
                            return '/'
                        },
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [
                                { value: 1, text: 'Samoplačniško' },
                                { value: 2, text: 'Zdravstveno' },
                                { value: 3, text: 'Subvencionirano s strani občine' },
                                { value: 4, text: 'Subvencionirano s strani občine in zdravstva' }
                            ]
                        }
                    },
                    {
                        field: 'date_created',
                        label: 'Datum oddaje',
                        tdClass: 'align-middle',
                        formatFn: (value) => {
                            return this.$dayjs(value).format('DD.MM.YYYY')
                        }
                    },
                    {
                        field: 'status',
                        label: 'Status',
                        tdClass: 'align-middle',
                        display_type: 2,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [
                                { value: 1, text: 'Privzeto' },
                                { value: 2, text: 'Zavrnjeno' }
                            ]
                        }
                    },
                    {
                        field: 'files',
                        label: 'Datoteke',
                        tdClass: 'align-middle',
                        display_type: 4
                    },
                    {
                        field: 'actions',
                        label: 'Dejanja',
                        tdClass: 'align-middle',
                        display_type: 3
                    }
                ],
                items: [],
                terms:[],
                initialSearchByEMSO: ''
            }
        },
        methods: {
            async loadData() {
                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.get(`/api/private/v1/submission/?${this.initialSearchByEMSO ? `emso=${  this.initialSearchByEMSO}` : ''}`)
                    this.items = response.data ?? []
                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            async submissionDeny(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(`Ali res želite zavrniti vlogo: ${data.first_name} ${data.last_name}`)
                    if (!confirmDelete) return
                    this.$store.commit('app/START_LOADING')
                    await this.$http.put(`/api/private/v1/submission/${data.id}/deny`)
                    this.$printSuccess('Vloga je bila zavrnjena')
                    await this.loadData()
                } catch (error) {
                    this.$printError('Prišlo je do napake pri zavračanju vloge')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            async downloadPdf(fileName) {
                try {
                    this.$store.commit('app/START_LOADING')
                    window.open(`/api/public/v1/download_file/${fileName}`)
                } catch (error) {
                    this.$printError('Prišlo je do napake pri prenosu pdf')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            gender(gender) {
                return gender === 1 ? 'M' : gender === 2 ? 'Ž' : ''
            },
            async loadTerms() {
                try {
                    this.$store.commit('app/START_LOADING')
                    const response = await this.$http.get('/api/private/v1/term/')
                    this.terms = response.data ?? []

                    const newArray = this.terms.map(term => {
                        return {
                            value: term.name,
                            text: term.name
                        }
                    })
                    this.$set(this.columns[3].filterOptions, 'filterDropdownItems', newArray)

                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            }
        },
        async mounted() {
            this.initialSearchByEMSO = this.$route.query.emso ?? ''
            await this.loadTerms()
            await this.loadData()

        }
    }
</script>

<style scoped>
.text-primary {

}
</style>
